import BaseApi from "../BaseApi";
import { AuthConverter } from "./AuthConverter";

class AuthApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/user`;
    this.converter = new AuthConverter();
  }

  signIn = (login, password) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/login`, {
      username: login,
      password: password,
    });

  checkAuth = () =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/check`,
      null,
      null,
      this.converter.convertUserData,
    );

  getBalance = () =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/balance`,
      null,
      null,
      this.converter.convertBalance,
    );

  logOut = () => this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/logout`);

  sendToPhoneCode = (phone) => {
    return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/phone/code`,
      { phone },
      { headers: { "Content-Type": "application/json" } },
    );
  };

  sendEmail = (login) => {
    return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/password/restore`,
      { email: login },
    );
  };

  getPhoneCode = (login) => {
    return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/password/restore/phone`,
      { phone: login },
    );
  };

  changePassword = (pass, code) => {
    return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/password/restore/new`,
      { code: code, new_password: pass },
    );
  };

  changePasswordByPhone = (login, pass, phoneCode) => {
    return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/password/restore/phone/new`,
      { phone: login, new_password: pass, code: phoneCode },
    );
  };
}

export default AuthApi;
